import React, { useEffect, useState } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { StyledArticle, StyledButton } from '../../styles';
import {
	StyledFilesTable,
	StyledForm,
	StyledInput,
	StyledLabel,
} from '../../styles/przetarg';

import { Layout } from '../../components/Layout';
import { MessageDiv } from '../../styles/formularz';
import { StyledH2 } from '../../styles/common';
import { StyledTableFileContainer } from '../../styles/lista';
import axios from '../../config/axios';
import { navigate } from 'gatsby';

import { SEO } from '../../components/SEO';

export const Head = () => (
	<SEO 
		title='Przetarg'
		description='Podgląd przetargu.'
	/>);


const PokazPage = () => {
	const [id, setId] = useQueryParam('id', StringParam);
	const [title, setTitle] = useState('');
	// const [description, setDescription] = useState('');
	const [info, setInfo] = useState('');
	const [files, setFiles] = useState([]);

	useEffect(() => {
		getTender();
		document.title = `Przetarg ${id.replace(/_/g, '/')}`;
	}, []);

	const getTender = () => {
		try {
			(async () => {
				const response = await axios.get('/get_tender.php', {
					params: { id },
				});
				if (response?.status === 200) {
					const data = response.data;
					setFiles(data.files);
					setTitle(data.title);
					// setDescription(data.description);
					setInfo(data.info);
				}
			})();
		} catch (e) {}
	};

	if (!id) {
		return null;
	}

	if (
		!/^[0-9]{3}_[0-9]{4}$/.test(id) &&
		!/^[0-9]{2}_[0-9]{2}_[0-9]{4}$/.test(id)
	) {
		return null;
	}

	return (
		<Layout>
			<StyledArticle>
				<StyledH2 as="h1" color="info">
					{`Przetarg ${id.replace(/_/g, '/')}`}
				</StyledH2>
				<MessageDiv>
					<StyledForm>
						<h3>{title}</h3>
						<div
							style={{
								hyphens: 'auto',
								overflowWrap: 'break-word',
								wordBreak: 'break-word',
								wordWrap: 'break-word',
							}}
							dangerouslySetInnerHTML={{ __html: info }}
						></div>

						{files.length > 0 ? (
							<>
								<StyledLabel>Pliki:</StyledLabel>
								<StyledTableFileContainer>
									<StyledFilesTable>
										<thead>
											<tr>
												<th></th>
												<th>Nazwa</th>
												<th>Rozmiar</th>
											</tr>
										</thead>
										<tbody>
											{files.map((file: any) => (
												<tr key={file.name}>
													<td width="32px" style={{ textAlign: 'left' }}>
														<span
															className="file-icon file-icon-md"
															data-type={file.name
																.toLowerCase()
																.substr(
																	file.name.lastIndexOf('.') + 1,
																	file.name.length
																)
																.slice(-4)}
														></span>
													</td>
													<td width="*">
														<a
															href={`https://api.mzk.bydgoszcz.pl/uploads/przetargi/${id}/${file.name}`}
															target="_blank" rel="noreferrer"
														>
															{file.name.toUpperCase()}
														</a>
													</td>
													<td width="48px" style={{ textAlign: 'center' }}>
														{Math.round(file.size / 1024)} KB
													</td>
												</tr>
											))}
										</tbody>
									</StyledFilesTable>
								</StyledTableFileContainer>
							</>
						) : null}
					</StyledForm>
				</MessageDiv>
			</StyledArticle>
		</Layout>
	);
};

export default PokazPage;
